$primary: #33434e;
$secondary: #56bc8a;
$ternary: #333;

$body-bg: rgba(0, 0, 0, 0.75);
$body-color: #555;

$border-radius: 3px;

$heading-color: #00f2ff;

$color-black: hsl(0, 0%, 5%);
$color-color: hsl(0, 0%, 100%);

$color-java: hsl(15, 100%, 60%);
$color-css: hsl(57, 100%, 60%);
$color-ts: hsl(220, 100%, 60%);
$color-node: hsl(291, 100%, 60%);
$color-react: hsl(110, 100%, 60%);
$color-angular: hsl(0, 100%, 60%);

$porcent-java: 75;
$porcent-css: 55;
$porcent-ts: 72;
$porcent-node: 65;
$porcent-react: 72;
$porcent-angular: 60;
