@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../variables';

.card {
    overflow: hidden;
    transition: border 0.5s;

    .card-body {
        z-index: 10;
        .card-text {
            color: $gray-800;
        }
    }
    .card-img-top {
        width: 100%;
        height: 18rem;
        object-fit: cover;
    }
    img {
        transition: all 0.5s;
    }
    &:hover {
        border-color: $primary;

        .card-text {
            text-shadow: 1px -1px 1px $white;
        }
    }
}

.no-border {
    border: none;
}

.full-height {
    height: 100%;
    padding-bottom: 30px;
}
