@import '../../variables';

.footer-paralax {
    padding: 3rem 0 0 0;
}

footer {
    text-align: center;
    color: #fff;
    padding-bottom: 4rem;

    .copyright {
        margin-bottom: 0.3rem;
    }
}

.socials {
    padding: 1.5rem 0;

    li {
        display: inline-block;
    }

    .ico-circle {
        height: 45px;
        width: 45px;
        font-size: 2rem;
        border-radius: 50%;
        line-height: 1.4;
        margin: 0 15px 0 0;
        box-shadow: 0 0 0 3px $primary;
        transition: all 500ms ease;
        color: #fff;

        &:hover {
            background-color: $primary;
            box-shadow: 0 0 0 3px #cde1f8;
            transition: all 500ms ease;
        }
    }
}

.ico-circle {
    height: 100px;
    width: 100px;
    font-size: 2rem;
    border-radius: 50%;
    line-height: 1.55;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 0 10px $primary;
    display: block;
}

.overlay-mf {
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}

.paralax-mf {
    position: relative;
    padding: 5rem 0;
}
