@import '../../variables';

.back-to-top {
    position: fixed;
    display: none;
    background: $heading-color;
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 11;

    &:hover {
        color: $heading-color;
        background: #fff;
    }

    svg {
        margin-top: 12px;
    }
}

@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
    }
}
