.intro {
    height: 100vh;
    position: relative;
    color: #fff;

    .intro-content {
        text-align: center;
        position: absolute;
    }
    .overlay-intro {
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0px;
        padding: 0;
        height: 100%;
        width: 100%;
        opacity: 0.9;
    }
    .intro-title {
        color: #fff;
        font-weight: 600;
        font-size: 3rem;

        @media (min-width: 768px) {
            font-size: 4.5rem;
        }
    }
    .intro-subtitle {
        font-size: 1.5rem;
        font-weight: 300;

        @media (min-width: 768px) {
            font-size: 2.5rem;
        }
    }
}
